// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-article-template-js": () => import("./../src/templates/articleTemplate.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-topic-template-js": () => import("./../src/templates/topicTemplate.js" /* webpackChunkName: "component---src-templates-topic-template-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-article-images-js": () => import("./../src/pages/article-images.js" /* webpackChunkName: "component---src-pages-article-images-js" */),
  "component---src-pages-articles-js": () => import("./../src/pages/articles.js" /* webpackChunkName: "component---src-pages-articles-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-now-js": () => import("./../src/pages/now.js" /* webpackChunkName: "component---src-pages-now-js" */),
  "component---src-pages-photography-js": () => import("./../src/pages/photography.js" /* webpackChunkName: "component---src-pages-photography-js" */),
  "component---src-pages-read-js": () => import("./../src/pages/read.js" /* webpackChunkName: "component---src-pages-read-js" */),
  "component---src-pages-topics-js": () => import("./../src/pages/topics.js" /* webpackChunkName: "component---src-pages-topics-js" */)
}

