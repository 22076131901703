module.exports = [{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"isIconAfterHeader":true,"elements":["h1","h2","h3"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140934886-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jules Chevalier","short_name":"Jules Chevalier","description":"Jules Chevalier is a designer & engineer who writes about emotional wellness, intentional living and inclusion.","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"src/images/icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"e6de233e660e7a30532953dd5d58d833"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
